import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"

const EnvironmentalStudiesPage = () => {
  const data = useStaticQuery(graphql`
    query {
      reportImg: file(relativePath: { eq: "environmental.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Environmental Studies" />
      <Hero
        pageTitle="Environmental Studies"
        breadcrumbText="Services"
        breadcrumbLink="/#services"
      />
      <section className="section-1 highlights image-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <span className="pre-title m-0">The NEPA Process</span>
              <h2>
                Environmental{" "}
                <span className="featured">
                  <span>Studies</span>
                </span>
              </h2>
              <p className="text-max-800">
                Environmental Assessments for airports are administered by the
                Federal Aviation Administration as the lead federal agency. The
                purpose of these studies is to ensure that the National
                Environmental Policy Act, 1969 (NEPA) is uniformly administered
                for all Federal actions at eligible airports.
              </p>
              <p>
                The NEPA process addresses impacts of Federal actions on the
                human environment, including noise, socioeconomic, land uses,
                air quality, and water quality. NEPA provides a means for
                assuring that environmental concerns and interests of the
                public, Federal, State, or local agencies, and Tribes are
                appropriately considered as part of the decision making process.
                NEPA also provides a means for efficiently complying with
                related statutes, orders, and regulations.
              </p>
              <p>
                Effective, efficient, and timely environmental analyses, public
                involvement, and interagency and intergovernmental coordination
                depend upon determining the appropriate level of review early in
                planning, budgeting, and scheduling. A Finding of No Significant
                Impact (FONSI) is the desired outcome for clients with
                development pending environmental analyses.
              </p>
              <Link to="/contact">Contact us about Environmental Studies</Link>
            </div>
            <div className="col-lg-4 mt-5">
              <div className="d-none d-lg-block report-shaddow">
                <Img fluid={data.reportImg.childImageSharp.fluid} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default EnvironmentalStudiesPage
